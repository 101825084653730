<script setup>
import { onMounted, ref } from 'vue';

const model = defineModel({
    type: String,
    required: true,
});

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });


</script>

<template>
    <input
        class="text-base border border-gray-300 rounded-md shadow focus:border-indigo-500 focus:ring-indigo-500 text-primary"
        v-model="model"
        ref="input"
    />
</template>
